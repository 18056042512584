import React from "react"
import styled from "@emotion/styled"
import CaseStudyItem from "./CaseStudyItem"
import RightContainer from "../Partials/RightContainer"

const CaseStudyList = ({ caseStudies = [] }) => {
    return (
        <ListContainer>
            <RightContainer>
                <List>
                    {caseStudies.map(({ id, ...caseStudy }) => (
                        <CaseStudyItem
                            key={id}
                            caseStudy={caseStudy}
                            isHome={false}
                        />
                    ))}
                </List>
            </RightContainer>
        </ListContainer>
    )
}
export default CaseStudyList

const ListContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`

const List = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
`
